<template>
  <div>
    <div class="summary">
      <div class="summary-item">
        <div class="summary-item-title">金牌合伙人人数</div>
        <div class="summary-item-number">{{ summary }}</div>
      </div>
      <!-- <div class="summary-item">
        <div class="summary-item-title">金牌合伙人团队总今日订单</div>
        <div class="summary-item-number">{{ summary.visitCount || 0 }}</div>
      </div>
      <div class="summary-item">
        <div class="summary-item-title">金牌合伙人团队总今日销售额</div>
        <div class="summary-item-number">{{ summary.orderCount || 0 }}</div>
      </div> -->
    </div>

    <Count />

    <Amount />

  </div>
</template>

<script>
import * as memberStatistics from '@/api/memberStatistics'
import Count from './count'
import Amount from './amount'

export default {
  components: {
    Count,
    Amount
  },
  data () {
    return {
      summary: 0
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const summary = await memberStatistics.summary({})
      this.summary = summary
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #FFFFFF;
  height: 140px;
  &-item{
    height: 100%;
    display: grid;
    align-content: center;
    text-align: center;
    // border-right: 1px solid #D9E0F0;
    &-title{
      font-size: 16px;
      color: #7D7E8E;
    }
    &-number {
      font-size: 32px;
      font-weight: bold;
      color: #505162;
      line-height: 42px;
    }
  }
}

</style>
