<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <div class="card-content-title">金牌合伙人团队销售额排名</div>
    </div>
    <div class="card-content">
      <ve-histogram :data="chartData" :extend="extend"></ve-histogram>
    </div>
  </div>
</template>

<script>
import * as memberStatistics from '@/api/memberStatistics'
// import * as R from 'ramda'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      chartData: {
        columns: ['名称', '订单金额'],
        rows: []
      },
      setting: {
        type: 'histogram',
        xAxisType: 'value'
      },
      extend: {
        color: ['#1D90FB'],
        legend: {
          show: false
        },
        barMaxWidth: '100',
        barMinWidth: '50'
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData (date = 30) {
      const data = await memberStatistics.orderAmount(getDateRange(date))
      this.handleData(data)
    },
    onTabChange (val) {
      this.fetchData(val)
    },
    async onDateChange (data) {
      const res = await memberStatistics.orderAmount(fmtDataRange(data))
      this.handleData(res)
    },
    handleData (data) {
      this.chartData.rows = data.map(o => {
        o['名称'] = o.nickName
        o['订单金额'] = this._pennyToYuan(o.orderAmount) || 0
        return o
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #FFFFFF;
  height: 550px;
  margin: 20px auto;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #D9E0F0;

    span {
      color: #999999;
    }
  }

  &-content {
    padding: 20px;
    /* width: 100%; */

    &-title {
      text-align: left;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7D7E8E;
      line-height: 25px;
      margin-right: 20px;
      &:before {
        content: '';
        background: #E38529;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 5px;
      }
    }
  }

  &-chart {
    &-title {
      margin-top: -20px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7D7E8E;
      line-height: 25px;
    }
  }

  &-table {
    max-height: 440px;
    padding: 20px;
    overflow: auto;
  }
}

</style>
