import moment from 'moment'

// const Day = 24 * 60 * 60 * 1000

const fmt = t => moment(t).format('YYYY-MM-DD')

export const getDateRange = (day = 0) => {
  const start = moment().startOf('day').subtract(day - 1, 'day')
  const end = moment().endOf('day')
  return {
    startDate: fmt(start),
    endDate: fmt(end)
  }
}

export const fmtDataRange = (dates) => {
  const start = fmt(dates[0])
  const end = fmt(dates[1])
  return {
    startDate: start,
    endDate: end
  }
}
