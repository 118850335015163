<template>
  <div class="container">
    <div class="group">
      <el-button
        v-for="item in options"
        :key="item.key"
        class="group-option"
        :class="{'group-active': value === item.key}"
        @click="handleClick(item.key)"
      >
        {{item.label}}
      </el-button>
    </div>

    <div style="display: flex; align-items: center">
      <el-date-picker
        v-model="date"
        type="daterange"
        :clearable="false"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" @click="handleSearch" style="margin-left: 10px;">查询</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: 30,
      date: [undefined, undefined],
      options: [
        { label: '今天', key: 1 },
        { label: '近7天', key: 7 },
        { label: '近30天', key: 30 }
      ]
    }
  },
  methods: {
    handleClick (val) {
      this.value = val
      this.date = []
      this.$emit('onChange', val)
    },
    handleSearch () {
      this.value = -1
      this.$emit('onDateChange', this.date)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin-left: 20px;
  display: grid;
  grid-template-columns: auto 280px;
  align-items: center;
}
.group {
  margin-top: 0;
  &-option{
    margin-right: 10px;
  }

  &-active {
    background-color: #1D90FB;
    color: #FFFFFF;
  }
}

</style>
